import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import {Link} from "gatsby"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
export default function ContactUs() {
  const { loaded, error, formCreated } = useHubspotForm({
    region: "na1",
    portalId: "19573488",
    formId: "1bc4eac9-1a8c-476e-8e4f-58cff8896631",
    target: "#my-hubspot-form-new",
  });
  return (
    <div>
      <Helmet>
        <title>OIC - Sitemap</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
        <div style={{ padding: "90px 0", background: "#000" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <h1
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    color: "#fff",
                  }}
                >
                  Site Map
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row ">
                <ul style={{color:"#303030", padding:"60px 0"}}>
                    <li><Link to="/" style={{color:"#303030"}}>Home</Link></li>
                    <li><Link to="/#products" style={{color:"#303030"}}>Product</Link></li>
                    <li><Link to="/about-us/" style={{color:"#303030"}}>About Us</Link></li>
                    <li><Link to="/contact-us/" style={{color:"#303030"}}>Reach Us</Link></li>
                    <li><Link to="/#blog" style={{color:"#303030"}}>Blog</Link></li>
                    <li><Link to="/market-place/" style={{color:"#303030"}}>Market Place</Link></li>
                    <li><Link to="/virtual-mirror/" style={{color:"#303030"}}>Virtual Mirror</Link></li>
                    <li><Link to="/pos/" style={{color:"#303030"}}>POS</Link></li>
                    <li><Link to="/crm/" style={{color:"#303030"}}>CRM</Link></li>
                    <li><Link to="/lens-simulation/" style={{color:"#303030"}}>Lens Simulation</Link></li>
                    <li><Link to="/website-building/" style={{color:"#303030"}}>Website Builder</Link></li>
                    
                </ul>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
